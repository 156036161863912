import React from 'react'
import Header from '../components/Header'
import Formulaire from '../components/Formulaire'
import Footer from '../components/Footer'
import Socials from '../components/Socials'

const Contact = () => {
  return (
    <div>
      <Header />
      <Formulaire />
      <Socials />
      <Footer />
    </div>
  )
}

export default Contact
;<Header />
