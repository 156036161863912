import React from 'react';
import '../styles/components/navigation.scss'
import { NavLink } from 'react-router-dom';

const Navigation = () => {
    return (
        <div className='Navbar'>
            <ul className='navlist'>
                <NavLink to='/' className={(nav)=>(nav.isActive ? "nav-active" : "")}>
                    <li>Accueil</li>
                </NavLink>
                <NavLink to='/suivis' className={(nav)=>(nav.isActive ? "nav-active" : "")}>
                    <li>Suivis</li>
                </NavLink>
                <NavLink to='/prestations' className={(nav)=>(nav.isActive ? "nav-active" : "")}>
                    <li>Prestations</li>
                </NavLink>
                <NavLink to='/ebook' className={(nav)=>(nav.isActive ? "nav-active" : "")}>
                    <li>E-Book</li>
                </NavLink>
                <NavLink to='/apropos' className={(nav)=>(nav.isActive ? "nav-active" : "")}>
                    <li>A Propos</li>
                </NavLink>
                <NavLink to='/contact' className={(nav)=>(nav.isActive ? "nav-active" : "")}>
                    <li>Contact</li>
                </NavLink>
            </ul>
        </div>
    );
};

export default Navigation;