import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer'
import Carousel from '../components/Carousel';
import '../styles/pages/apropos.scss'

const Apropos = () => {
    return (
        <div>
            <Header />
            <div className='carrousel-container'>                
                <Carousel/>
            </div>
            <Footer/>
        </div>
    );
};

export default Apropos;