import React from 'react';
import '../styles/components/header.scss'
import Logocoach from './Logocoach';
import logotel from '../assets/img/logotelmarron.svg';
import logoinsta from '../assets/img/logoinstamarron.svg'
import Navigation from './Navigation';

const Header = () => {
    return (
        <div className='header'>
            <div className='header-logos'>
                <Logocoach />
                <div className='header-little-logos'>
                    <img src={logotel} alt="Téléphone" />
                    <a href="https://www.instagram.com/sandr.l/" target='_blank' rel="noreferrer"><img src={logoinsta} alt="Instagram" /></a>
                </div>
            </div>
            <div className='header-navigation'>
                <Navigation/>
            </div>
        </div>
    );
};

export default Header;