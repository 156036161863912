import React from 'react'
import imgprincipale from '../assets/img/photoshoot-couleur-10.jpeg'
import '../styles/components/imgaccueil.scss'

const Imgaccueil = () => {
    return (
        <div className="accueil-element">
            <h1>Atteins tes Objectifs Bien Etre</h1>
            <div className="imgaccueil">
                <img src={imgprincipale} alt="Salade et Toasts Chèvre-Figues" />
            </div>
        </div>
    )
}

export default Imgaccueil
