import React from 'react';
import '../styles/components/logosaccueil.scss'
import calendar from '../assets/img/calendarlogomarron.svg'
import cupcake from '../assets/img/logocupcakemarron.svg'
import paperpen from '../assets/img/paperpenlogomarron.svg'

const Logosaccueil = () => {
    return (
        <div className='logosaccueil-container'>
            <div className='coaching-cupcake logotext-container'>
                <img src={cupcake} alt="Cupcake" />
                <p>Coaching en Nutrition</p>
            </div>
            <div className='plan-paperpen logotext-container'>
                <img src={paperpen} alt="Document" />
                <p>Plan Personnalisé</p>
            </div>
            <div className='suivi-calendrier logotext-container'>
                <img src={calendar} alt="Calendrier" />
                <p>Suivi Régulier</p>
            </div>
        </div>
    );
};

export default Logosaccueil;