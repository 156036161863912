import React from 'react'
import '../styles/pages/mentionslegales.scss'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Mentionslegales = () => {
    return (
        <div>
            <Header />
            <div className="mentions-container">
                <h2>Mentions Légales</h2>
                <h3>1 – Édition du site</h3>
                <p>
                    En vertu de
                    <a
                        href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000801164#LEGIARTI000042038977"
                        target="/blank"
                        rel="noreferrer"
                    >
                        l’article 6 de la loi n° 2004-575 du 21 juin 2004
                    </a>
                    pour la confiance dans l’économie numérique, il est précisé
                    aux utilisateurs du site internet sandralagouarde.com
                    l’identité des différents intervenants dans le cadre de sa
                    réalisation et de son suivi :
                </p>
                <p>Propriétaire du site : Mr Charles Huault</p>
                <p>
                    Contact :
                    <a href="mailto:charles.huault@ch-webvision.fr">
                        charles.huault@ch-webvision.fr
                    </a>
                </p>
                <p>
                    Site :
                    <a href="https://www.ch-webvision.fr/">
                        https://www.ch-webvision.fr/
                    </a>
                </p>
                <p>Identification de l’entreprise : Mr Charles Huault</p>
                <p>SIRET : 98168644700013</p>
                <p>Délégué à la protection des données : Mr Charles Huault</p>
                <h3>2 – Propriété intellectuelle et contrefaçons</h3>
                <p>
                    Mme Sandra LAGOUARDE est propriétaire des droits de
                    propriété intellectuelle et détient les droits d’usage sur
                    tous les éléments accessibles sur le site internet,
                    notamment les textes, images, graphismes, logos, vidéos,
                    architecture, icônes et sons. Toute reproduction,
                    représentation, modification, publication, adaptation de
                    tout ou partie des éléments du site, quel que soit le moyen
                    ou le procédé utilisé, est interdite, sauf autorisation
                    écrite préalable de Mme Sandra LAGOUARDE.
                </p>
                <p>
                    Toute exploitation non autorisée du site ou de l’un
                    quelconque des éléments qu’il contient sera considérée comme
                    constitutive d’une contrefaçon et poursuivie conformément
                    aux dispositions des{' '}
                    <a
                        href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032655082"
                        target="/blank"
                        rel="noreferrer"
                    >
                        articles L.335-2 et suivants du Code de Propriété
                        Intellectuelle.
                    </a>
                </p>
                <h3>3 – Limitations de responsabilité</h3>
                <p>
                    Mme Sandra Lagouarde ne pourra être tenue pour responsable
                    des dommages directs et indirects causés au matériel de
                    l’utilisateur, lors de l’accès au site
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com.
                    </a>
                </p>
                <p>
                    Mme Sandra Lagouarde décline toute responsabilité quant à
                    l’utilisation qui pourrait être faite des informations et
                    contenus présents sur
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com.
                    </a>
                </p>
                <p>
                    Mme Sandra Lagouarde s’engage à sécuriser au mieux le site
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com.
                    </a>
                    , cependant sa responsabilité ne pourra être mise en cause
                    si des données indésirables sont importées et installées sur
                    son site à son insu.
                </p>
                <p>
                    Des espaces interactifs (espace contact) sont à la
                    disposition des utilisateurs. Mme Sandra Lagouarde se
                    réserve le droit de supprimer, sans mise en demeure
                    préalable, tout contenu déposé dans cet espace qui
                    contreviendrait à la législation applicable en France, en
                    particulier aux dispositions relatives à la protection des
                    données.
                </p>
                <p>
                    Le cas échéant, Mme Sandra Lagouarde se réserve également la
                    possibilité de mettre en cause la responsabilité civile
                    et/ou pénale de l’utilisateur, notamment en cas de message à
                    caractère raciste, injurieux, diffamant, ou pornographique,
                    quel que soit le support utilisé (texte, photographie …).
                </p>
                <h3>4 – CNIL et gestion des données personnelles</h3>
                <p>
                    Conformément aux dispositions de
                    <a
                        href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000886460"
                        target="/blank"
                        rel="noreferrer"
                    >
                        la loi 78-17 du 6 janvier 1978 modifiée
                    </a>
                    , l’utilisateur du site
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com.
                    </a>{' '}
                    dispose d’un droit d’accès, de modification et de
                    suppression des informations collectées. Pour exercer ce
                    droit, envoyez un message à notre Délégué à la Protection
                    des Données : Mr Charles Huault à l’adresse mail :
                    <a href="mailto:charles.huault@ch-webvision.fr">
                        charles.huault@ch-webvision.fr
                    </a>
                </p>
                <p>
                    Pour plus d’informations sur la façon dont nous traitons vos
                    données (type de données, finalité, destinataire…), lisez
                    notre
                    <a
                        href="http://www.sandralagouarde.com/politiqueconfidentialite"
                        target="/blank"
                        rel="noreferrer"
                    >
                        http://www.sandralagouarde.com/politiqueconfidentialite.
                    </a>
                </p>
                <h3>5 – Liens hypertextes et cookies</h3>
                <p>
                    Le site{' '}
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com.
                    </a>{' '}
                    contient des liens hypertextes vers d’autres sites et dégage
                    toute responsabilité à propos de ces liens externes ou des
                    liens créés par d’autres sites vers
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com.
                    </a>
                    La navigation sur le site est susceptible de provoquer
                    l’installation de cookie(s) sur l’ordinateur de
                    l’utilisateur.
                </p>
                <p>
                    Un « cookie » est un fichier de petite taille qui enregistre
                    des informations relatives à la navigation d’un utilisateur
                    sur un site. Les données ainsi obtenues permettent d’obtenir
                    des mesures de fréquentation, par exemple.
                </p>
                <p>
                    Vous avez la possibilité d’accepter ou de refuser les
                    cookies en modifiant les paramètres de votre navigateur.
                    Aucun cookie ne sera déposé sans votre consentement.
                </p>{' '}
                <p>
                    Les cookies sont enregistrés pour une durée maximale de 3
                    mois.
                </p>
                <p>
                    Pour plus d’informations sur la façon dont nous faisons
                    usage des cookies, lisez notre
                    <a
                        href="http://www.sandralagouarde.com/politiqueconfidentialite"
                        target="/blank"
                        rel="noreferrer"
                    >
                        http://www.sandralagouarde.com/politiqueconfidentialite.
                    </a>
                </p>
                <h3>6 – Droit applicable et attribution de juridiction</h3>
                <p>
                    Tout litige en relation avec l’utilisation du site
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com
                    </a>{' '}
                    est soumis au droit français. En dehors des cas où la loi ne
                    le permet pas, il est fait attribution exclusive de
                    juridiction aux tribunaux compétents de Paris.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default Mentionslegales
