import React from 'react'
import '../styles/components/prestationstextebloc.scss'

const Prestationstextebloc = () => {
    return (
        <div className="prestations-texte-container">
            <h3>Choisis ton programme</h3>
            <p>
                Suite à ce premier appel, nous définirons ensemble le programme
                le plus adapté à tes besoins et aux objectifs voulus
            </p>
            <p>
                Tu recevras en suivant les documents et informations nécessaires
                au démarrage de ton suivi
            </p>
        </div>
    )
}

export default Prestationstextebloc
