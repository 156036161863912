import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import svgmarron from '../assets/img/Vectosvgmarronsuivis.svg'
import '../styles/pages/suivis.scss'
import Suivisphotoblock from '../components/Suivisphotoblock';
import Suivistexteblock from '../components/Suivistexteblock';

const Suivis = () => {
    return (
        <div>
            <Header />
            <div className='page-suivis-container'>                
                <Suivisphotoblock />
                <div className='svgfondmarron'>
                    <img src={svgmarron} alt="" className='svg-vague-marron' />
                    <Suivistexteblock />
                </div>                
            </div>            
            <Footer/>
        </div>
    );
};

export default Suivis;