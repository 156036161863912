import React from 'react'
import logoinsta from '../assets/img/logoinstasaumon.svg'
import logomail from '../assets/img/logomailsaumon.svg'
import logotel from '../assets/img/logotelsaumon.svg'
import '../styles/components/socials.scss'

const Socials = () => {
    return (
        <div className="contact-socials-container">
            <div className="contact-mail-container socials-link-block">
                <h4>Mon adresse email</h4>
                <div className="text-mail-container ">
                    <a
                        href="mailto:contact@sandralagouarde.com"
                        className="social-link"
                    >
                        <img
                            src={logomail}
                            alt="Mail"
                            className="custom-color"
                        />
                        <p>contact@sandralagouarde.com</p>
                        <img
                            src={logomail}
                            alt="Mail"
                            className="custom-color"
                        />
                    </a>
                </div>
            </div>
            <div className="contact-insta-container socials-link-block">
                <h4>Contactes moi sur Instagram</h4>
                <div className="text-insta-container">
                    <a
                        href="https://www.instagram.com/sandr.l/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-link"
                    >
                        <img src={logoinsta} alt="Instagram" />
                        <p>Clique ici pour accéder à Instagram</p>
                        <img src={logoinsta} alt="Instagram" />
                    </a>
                </div>
            </div>
            <div className="contact-whatsapp-container socials-link-block">
                <h4>Disponible par message sur Whatsapp:</h4>
                <p>Lundi-Vendredi: De 9h à 18h</p>
                <div className="text-whatsapp-container">
                    <a
                        href="whatsapp://send?phone=+33665667040"
                        className="social-link"
                    >
                        <img src={logotel} alt="Whatsapp" />
                        <p>Clique ici pour ouvrir la conversation</p>
                        <img src={logotel} alt="Whatsapp" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Socials
