import React from 'react';
import Header from '../components/Header';
import Reservationsblock from '../components/Reservationsblock'
import Prestationstextebloc from '../components/Prestationstextebloc';
import Tarifsblock from '../components/Tarifsblock';
import Footer from '../components/Footer';

const Prestations = () => {
    return (
        <div className='prestations-page-container'>
            <Header />
            <Reservationsblock />
            <Prestationstextebloc />
            <Tarifsblock />
            <Footer/>
        </div>
    );
};

export default Prestations;