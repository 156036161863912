import React from 'react'
import '../styles/pages/politiqueconf.scss'
import Header from '../components/Header'
import Footer from '../components/Footer'

const Politiqueconf = () => {
    return (
        <div>
            <Header />
            <div className="politique-container">
                <h2>Politique de confidentialité</h2>
                <h3>1 – Introduction</h3>
                <p>
                    Dans le cadre de son activité, Mme Sandra Lagouarde est
                    amenée à collecter et à traiter des informations dont
                    certaines sont qualifiées de « données personnelles ». Mme
                    Sandra Lagouarde attache une grande importance au respect de
                    la vie privée, et n’utilise que des données de manière
                    responsable et confidentielle et dans une finalité bien
                    précise.
                </p>
                <h3>2 – Données personnelles</h3>
                <p>
                    Sur le site web
                    <a
                        href="https://www.sandralagouarde.com"
                        target="/blank"
                        rel="noreferrer"
                    >
                        https://www.sandralagouarde.com,
                    </a>
                    il y a 2 types de données susceptibles d’être recueillies :
                </p>
                <ul>
                    <li>
                        Les données transmises directement : Ces données sont
                        celles que vous nous transmettez directement, via le
                        formulaire de contact ou bien par contact direct par
                        email. Sont obligatoires dans le formulaire de contact
                        les champs « nom et prénom » et « email ».
                    </li>
                    <li>
                        Les données collectées automatiquement : Lors de vos
                        visites, une fois votre consentement donné, nous pouvons
                        recueillir des informations de type « web analytics »
                        relatives à votre navigation, la durée de votre
                        consultation, votre adresse IP, votre type et version de
                        navigateur. La technologie utilisée est le cookie.
                    </li>
                </ul>
                <h3>3 – Utilisation des données</h3>
                <p>
                    Les données que vous nous transmettez directement sont
                    utilisées dans le but de vous re-contacter et/ou dans le
                    cadre de la demande que vous nous faites. Les données « web
                    analytics » sont collectées de forme anonyme (en
                    enregistrant des adresses IP anonymes) par Google Analytics,
                    et nous permettent de mesurer l’audience de notre site web,
                    les consultations et les éventuelles erreurs afin
                    d’améliorer constamment l’expérience des utilisateurs. Ces
                    données sont utilisées par Mr Charles Huault, responsable du
                    traitement des données, et ne seront jamais cédées à un
                    tiers ni utilisées à d’autres fins que celles détaillées
                    ci-dessus.
                </p>
                <h3>4 – Base légale</h3>
                <p>
                    Les données personnelles ne sont collectées qu’après
                    consentement obligatoire de l’utilisateur. Ce consentement
                    est valablement recueilli (boutons et cases à cocher),
                    libre, clair et sans équivoque
                </p>
                <h3>5 – Durée de conservation</h3>
                <p>
                    Les données seront sauvegardées durant une durée maximale de
                    3 ans.
                </p>
                <h3>6 – Cookies</h3>
                <p>
                    Nous recevons, collectons et stockons toute information que
                    vous saisissez sur notre site web ou que vous nous
                    fournissez de toute autre manière. En outre, nous
                    recueillons l'adresse de protocole Internet (IP) utilisée
                    pour connecter votre ordinateur à l'Internet ; le login ;
                    l'adresse e-mail ; le mot de passe ; les informations sur
                    l'ordinateur. Nous pouvons utiliser des outils logiciels
                    pour mesurer et collecter des informations de session, y
                    compris les temps de réponse des pages, la durée des visites
                    sur certaines pages, les informations sur l'interaction
                    entre les pages et les méthodes utilisées pour naviguer hors
                    de la page. Nous recueillons également des informations
                    d'identification personnelle (y compris le nom, l'adresse
                    électronique), les commentaires, les réactions, les
                    recommandations et le profil personnel.
                </p>
                <h3>7 – Vos droits concernant les données personnelles</h3>
                <p>
                    Vous avez le droit de consultation, demande de modification
                    ou d’effacement sur l’ensemble de vos données personnelles.
                    Vous pouvez également retirer votre consentement au
                    traitement de vos données
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default Politiqueconf
