import React, { useEffect, useRef, useState } from 'react'
import emailjs from '@emailjs/browser'
import '../styles/components/formulaire.scss'

// Composant du formulaire de contact
const Formulaire = () => {
    const form = useRef()
    const [errors, setErrors] = useState({
        Prenom: '',
        Nom: '',
        email: '',
        message: '',
        tel: '',
    })
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    const sendEmail = (e) => {
        e.preventDefault()

        // Valider le champ Prenom avec au moins 5 caractères, sinon renvoyer une erreur
        if (form.current.Prenom.value.length < 5) {
            setErrors({ ...errors, Prenom: 'Au moins 5 caractères !' })
            return
        } else {
            setErrors({ ...errors, Prenom: '' })
        }

        // Valider le champ Nom avec au moins 5 caractères, sinon renvoyer une erreur
        if (form.current.Nom.value.length < 5) {
            setErrors({ ...errors, Nom: 'Au moins 5 caractères !' })
            return
        } else {
            setErrors({ ...errors, Nom: '' })
        }

        // Valider le champ email avec une adresse valide sinon renvoyer une erreur
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        if (!emailPattern.test(form.current.email.value)) {
            setErrors({ ...errors, email: 'Adresse mail invalide !' })
            return
        } else {
            setErrors({ ...errors, email: '' })
        }

        // Valider le champ téléphone avec un format spécifique
        const phonePattern =
            /^(?:(?:\+|00)33|0)[\s.-]*[1-9](?:(?:[\s.-]*\d){8})$/
        if (!phonePattern.test(form.current.tel.value)) {
            setErrors({ ...errors, tel: 'Numéro de téléphone invalide !' })
            return
        } else {
            setErrors({ ...errors, tel: '' })
        }

        // Valider le champ message avec au moins 50 caractères, sinon renvoyer une erreur
        if (form.current.message.value.length < 50) {
            setErrors({ ...errors, message: 'Au moins 50 caractères !' })
            return
        } else {
            setErrors({ ...errors, message: '' })
        }

        // Envoyer le formulaire si tout est valide
        emailjs
            .sendForm(
                'service_fvkt5rt',
                'template_wuhum6b',
                form.current,
                '32n-A75WksH7DtL_P'
            )
            .then(
                (result) => {
                    console.log(result.text)
                    setIsFormSubmitted(true) // Marquer le formulaire comme soumis avec succès
                    form.current.reset() // Réinitialiser le contenu du formulaire
                },
                (error) => {
                    console.log(error.text)
                }
            )
    }

    useEffect(() => {
        // Sélection de tous les textarea avec la classe "auto-expand"
        const textareas = document.querySelectorAll('textarea.auto-expand')

        // Ajout d'un gestionnaire d'événements "input" à chaque textarea
        textareas.forEach((textarea) => {
            textarea.addEventListener('input', function () {
                this.style.height = 'auto' // Réinitialisation de la hauteur à "auto" pour recalculer la hauteur réelle
                this.style.height = this.scrollHeight + 'px' // Ajustement de la hauteur en fonction du contenu
            })
        })

        // Nettoyage des gestionnaires d'événements lorsque le composant est démonté
        return () => {
            textareas.forEach((textarea) => {
                textarea.removeEventListener('input', function () {
                    this.style.height = 'auto'
                    this.style.height = this.scrollHeight + 'px'
                })
            })
        }
    }, [])

    return (
        // Corps du formulaire appellant les éléments créés plus haut
        <div className="form-block-container">
            <h2>
                Envoies moi un message si tu souhaites plus d'informations !
            </h2>
            {isFormSubmitted ? (
                <div className="success-message">Votre message est envoyé!</div>
            ) : (
                <div className="form-container">
                    <h4>Formulaire de Contact</h4>
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="prénom-nom-container">
                            <div className="prénom-container input-container">
                                <label htmlFor="Prenom">Prénom</label>
                                <input type="text" name="Prenom" id="Prenom" />
                                {errors.Prenom && (
                                    <div className="error">{errors.Prenom}</div>
                                )}
                            </div>
                            <div className="nom-container input-container">
                                <label htmlFor="Nom">Nom</label>
                                <input type="text" name="Nom" id="Nom" />
                                {errors.Nom && (
                                    <div className="error">{errors.Nom}</div>
                                )}
                            </div>
                        </div>
                        <div className="email-tel-container">
                            <div className="email-container input-container">
                                <label htmlFor="email">Email</label>
                                <input type="email" name="email" id="email" />
                                {errors.email && (
                                    <div className="error">{errors.email}</div>
                                )}
                            </div>
                            <div className="tel-container input-container">
                                <label htmlFor="tel">Téléphone</label>
                                <input type="tel" name="tel" id="tel" />
                                {errors.tel && (
                                    <div className="error">{errors.tel}</div>
                                )}
                            </div>
                        </div>

                        <div className="text-container">
                            <label htmlFor="message">Votre Message</label>
                            <textarea
                                className="auto-expand"
                                name="message"
                                rows="4"
                            />
                            {errors.message && (
                                <div className="error">{errors.message}</div>
                            )}
                        </div>

                        <input
                            type="submit"
                            value="Envoyer"
                            className="sendbutton"
                        />
                    </form>
                </div>
            )}
        </div>
    )
}

export default Formulaire
