import React from 'react'
import '../styles/components/tarifsblock.scss'

const Tarifsblock = () => {
    return (
        <div className="tarifs-block-container">
            <div className="tarifs-title">
                <h2>Les Tarifs</h2>
            </div>
            <div className="tarifs-container">
                <div className="tarif-plan-unique">
                    <h3>Plan Unique</h3>
                    <div className="tarif-col">
                        <h4>Plan sans Suivi</h4>
                        <p>
                            Suite à la consultation, et si tu te sens prêt(e) à
                            être autonome, je te fournis un compte rendu
                            détaillé, une répartition alimentaire si tu le
                            souhaites et un plan complet te correspondant en
                            prenant en compte les problématiques dont tu m'auras
                            fait part
                        </p>
                        <h4>100€</h4>
                    </div>
                </div>
                <div className="tarifs-solo-container">
                    <h3>Suivis Individuels</h3>
                    <div className="tarifs-colonnes">
                        <div className="tarif-suivi-3-solo tarif-col">
                            <h4>Suivi 3 Mois</h4>
                            <p>
                                Bilan nutritionnel, 6 séances de suivi en visio
                                et tous les supports nécessaires à la mise en
                                place de ton plan alimentaire*
                            </p>
                            <h4>180€</h4>
                        </div>
                        <div className="tarif-suivi-6-solo tarif-col">
                            <h4>Suivi 6 mois</h4>
                            <p>
                                Bilan nutritionnel, 12 séances de suivi en visio
                                pour un suivi encore plus approfondi, et tous
                                les supports nécessaires à la mise en place de
                                ton plan alimentaire*
                            </p>
                            <h4>330€</h4>
                        </div>
                    </div>
                </div>

                <div className="tarifs-duo-container">
                    <h3>Suivis en Duo</h3>
                    <div className="tarifs-colonnes">
                        <div className="tarif-suivi-3-duo tarif-col">
                            <h4>Suivi 3 Mois</h4>
                            <p>
                                Bilan nutritionnel individualisé, 6 séances de
                                suivi en visio (individuel ou groupé, vous avez
                                le choix!) et tous les supports nécessaires à la
                                mise en place de vos plans alimentaires*
                            </p>
                            <h4>300€</h4>
                        </div>
                        <div className="tarif-suivi-6-duo tarif-col">
                            <h4>Suivi 6 mois</h4>
                            <p>
                                Bilan nutritionnel, 12 séances de suivi en visio
                                (individuel ou groupé, vous avez le choix!) pour
                                un suivi encore plus approfondi, et tous les
                                supports nécessaires à la mise en place de vos
                                plans alimentaires*
                            </p>
                            <h4>570€</h4>
                        </div>
                    </div>
                </div>
                <p>
                    *Quelle que soit la formule de suivi que tu choisis, je
                    t'offre 10 recettes tirées de mon Ebook en cadeau !
                </p>
            </div>
        </div>
    )
}

export default Tarifsblock
