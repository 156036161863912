import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import '../styles/pages/error.scss'

const Error = () => {
    return (
        <div className='error-page-container'>
            <Header />            
            <h3>La page que tu recherches n'existe pas !</h3>
            <NavLink to='/' className={'errorlink'}>Clique ici pour retourner à l'accueil</NavLink>
            <Footer/>
        </div>
    );
};

export default Error;