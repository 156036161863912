import React from 'react'
import '../styles/components/avisclient.scss'
import msgilana from '../assets/img/CommentaireIlana .png'
import msgannemarie from '../assets/img/CommentaireAnne-Marie .png'
import avantilana from '../assets/img/Ilana1.jpg'
import apresilana from '../assets/img/Ilana2.jpg'

const Avisclient = () => {
    return (
        <div className="avisclient-container">
            <div className="avisclient-title">
                <h3>Ton avis compte beaucoup pour moi</h3>
            </div>
            <div className="clientscontainer">
                <div className="avis-ilana-container">
                    <div>
                        <img
                            src={msgilana}
                            alt="Message Ilana"
                            className="img-msg"
                        />
                        <p>Ilana (20ans)</p>
                    </div>
                    <div className="ilana-av-ap">
                        <div className="ilana-avant">
                            <img
                                src={avantilana}
                                alt="Ilana avant"
                                className="img-av-ap"
                            />
                            <p>Avant</p>
                        </div>
                        <div className="ilana-après">
                            <img
                                src={apresilana}
                                alt="Ilana après"
                                className="img-av-ap"
                            />
                            <p>Après</p>
                        </div>
                    </div>
                </div>
                <div className="avis-annemarie-container">
                    <img
                        src={msgannemarie}
                        alt="Message Anne-Marie"
                        className="img-msg"
                    />
                    <p>Anne-Marie (52ans)</p>
                </div>
            </div>
        </div>
    )
}

export default Avisclient
