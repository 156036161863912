import React from 'react';
import logocoach from '../assets/img/screenlogo.png'

const Logocoach = () => {
    return (
        <div>
            <img src={logocoach} alt="Sandra Coach Nutritionniste" className='logo-header' />
        </div>
    );
};

export default Logocoach;