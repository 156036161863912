import React, { useEffect, useState } from 'react'
import '../styles/components/instagram.scss'

const Instagram = () => {
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const accessToken =
            'IGQWROb2dJOGF1QWRXZA1NNdU5XbHJ6MVR5R2J0R2ZAWUUFrbmpUYktwQWFWMzd4NEwyZAGJ5WUdoZAktoeHRrV1ZAGaUl3VFF0TWRjQ0pjV0RBdGNPNy15RFRRSjJ6R29OS1RrUzFyWXh2TTBMQ3E3dkZAOTE0ySjFwTTQZD' // Remplacez par votre Token d'Accès Client
        const userId = '24690939977221378' // Remplacez par l'ID du compte Instagram

        const apiUrl = `https://graph.instagram.com/v12.0/${userId}/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink,timestamp&limit=5&access_token=${accessToken}`

        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => {
                setPosts(data.data || [])
            })
            .catch((error) => {
                console.error(
                    "Erreur lors de la requête à l'API Instagram",
                    error
                )
            })
    }, [])

    return (
        <div className="blocinsta-container">
            <h3>
                Suis moi sur Instagram pour des idées{' '}
                <br className="hide-on-small-screen" />
                recettes saines et gourmandes !
            </h3>
            <div className="instagram-posts">
                {posts.map((post) => (
                    <div key={post.id} className="instagram-post">
                        <a
                            href={post.permalink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={post.media_url} alt={post.caption} />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Instagram
