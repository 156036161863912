import React from 'react';
import Header from '../components/Header';
import Imgaccueil from '../components/Imgaccueil';
import Logosaccueil from '../components/Logosaccueil';
import Presentationaccueil from '../components/Presentationaccueil';
import Instagram from '../components/Instagram';
import Avisclient from '../components/Avisclient';
import Reservationsblock from '../components/Reservationsblock';
import Footer from '../components/Footer';

const Home = () => {
    return (
        <div>
            <Header />
            <Imgaccueil />
            <Logosaccueil />
            <Presentationaccueil />
            <Instagram />
            <Avisclient />
            <Reservationsblock />
            <Footer/>
        </div>
    );
};

export default Home;