import React from 'react'
import '../styles/components/suivistexteblock.scss'

const Suivistexteblock = () => {
    return (
        <div className="suivistextebloc-container">
            <h3>Consultation et plan unique :</h3>
            <p>
                Tu penses être autonome mais tu as besoin de conseils
                nutritionnels ? Des interrogations sur ce qu'est un régime
                équilibré ? <br />
                Cette formule est faite pour toi !
            </p>
            <ul>
                <li>Une visio d'environ une heure</li>
                <li>Un compte rendu détaillé</li>
                <li>Une répartition alimentaire (si demandé)</li>
                <li>De la documentation en fonction des problématiques</li>
            </ul>
            <h3>Consultation et suivi 3 ou 6 mois :</h3>
            <p>
                Suivi 100% personnalisé en ligne qui te permet d'avoir un
                accompagnement régulier et une communication constante avec moi,
                ta coach alimentaire, afin de rester motivé(e) !
            </p>{' '}
            <p>
                Ce suivi de proximité nous permet de créer un lien de confiance
                pour plus de résultats !
            </p>{' '}
            <p>
                {' '}
                L'objectif ? Reprendre confiance en toi, te sentir serein(e) et
                en paix avec la nourriture
            </p>
            <h3>Consultation et suivi EN DUO :</h3>
            <p>
                Envie de se motiver à deux ? En couple au sein du foyer ? Amis
                de salle de sport ? Ou encore collègue de travail ?
            </p>
            <p>
                La formule Duo permet de se motiver en équipe afin d'obtenir
                davantage de bien-être alimentaire et d'atteindre vos objectifs
                respectifs, qu'il s'agisse de prise ou de perte de poids!
            </p>
            <p>
                Les plans nutritionnels seront individualisés en fonction de
                chacun. Les consultations pourront se faire en équipe pour
                profiter de conseils nutritionnels ou en solo, je m'adapte à vos
                préférences et disponibilités !
            </p>
        </div>
    )
}

export default Suivistexteblock
