import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Ebookcontentblock from '../components/Ebookcontentblock';

const EBook = () => {
    return (
        <div>
            <Header />
            <Ebookcontentblock/>
            <Footer/>
        </div>
    );
};

export default EBook;