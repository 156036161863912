import React from 'react';
import svgrose from '../assets/img/Vectorsvgroseaccueil.svg'
import '../styles/components/presentationaccueil.scss'
import Accueilphotoblock from './Accueilphotoblock';
import Accueiltexteblock from './Accueiltexteblock';

const Presentationaccueil = () => {
    return (
        <div className='presentation-accueil-container'>
            <div className='svg-fond-rose'>
                <div className='svgaccueil'>
                    <img src={svgrose} alt="Fond Rose Vague" />
                </div>
                <div className='accueilphotoblock'>
                    <Accueilphotoblock/>
                </div>
                
            </div>
            <div className='acceuiltexteblock'>
                <Accueiltexteblock/>
            </div>
        </div>
    );
};

export default Presentationaccueil;