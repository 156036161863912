import React from 'react';
import '../styles/components/ebookimg.scss'

const Ebookimg = ({ cover }) => {
    
    return (
        <div className='ebook-img' style={{ backgroundImage: `url(${cover})`}}>            
        </div>
    );
};

export default Ebookimg;