import React from 'react'
import Ebookimg from './Ebookimg'
import ebookimg1 from '../assets/img/exbook1.png'
import ebookimg2 from '../assets/img/exbook2.png'
import ebookimg3 from '../assets/img/exbook3.png'
import ebookimg4 from '../assets/img/exbook4.png'
import fleche from '../assets/img/Arrow1ebook.svg'
import '../styles/components/ebookcontentblock.scss'

const Ebookcontentblock = () => {
    return (
        <div className="ebook-container">
            <h3>Découvre mon E-Book de recettes et astuces culinaires !</h3>
            <div className="ebook-img-btn-container">
                <div className="double-img-block">
                    <div className="ebookimg1-3">
                        <Ebookimg cover={ebookimg1} />
                    </div>
                    <div className="ebookimg2-4">
                        <Ebookimg cover={ebookimg2} />
                    </div>
                </div>
                <div className="btn-block">
                    {/* <p>Clique ici !</p>
                    <img src={fleche} alt="Flèche" />
                    <a href="tel:+33661426129">Découvrir</a> */}
                    <h3>Il arrive très bientôt!!</h3>
                </div>
                <div className="double-img-block">
                    <div className="ebookimg1-3">
                        <Ebookimg cover={ebookimg3} />
                    </div>
                    <div className="ebookimg2-4">
                        <Ebookimg cover={ebookimg4} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ebookcontentblock
