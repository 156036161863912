import React from 'react'
import '../styles/components/suivisphotoblock.scss'
import imgsandra from '../assets/img/photoshoot-couleur-2.jpg'

const Suivisphotoblock = () => {
    return (
        <div className="suivisphotobloc-container">
            <div className="photoblock-img">
                <img src={imgsandra} alt="Sandra" />
            </div>
            <div className="photoblock-text">
                <p>
                    Rééquilibre et retrouve une relation bienveillante avec ton
                    alimentation et atteins tes objectifs durablement !
                    Ensemble, je t'apprends à retrouver une alimentation
                    intuitive, être à l'écoute de ton corps, conserver le
                    plaisir alimentaire et pourquoi les restrictions et
                    frustrations sont à bannir.
                </p>
                <p>
                    Les programmes que je conçois sont 100% personnalisés, afin
                    d'entamer un rééquilibrage alimentaire à ton rythme et
                    adopter des quantités qui te sont adaptées. Mais aussi, et
                    surtout, pour que tu puisses retrouver une relation saine
                    avec ton alimentation et écouter ton corps.
                </p>
                <p>
                    Adopte un véritable mode de vie pour atteindre tes objectifs
                    !
                </p>
                <p>
                    Ton programme n'est pas un régime ! On bannit les
                    restrictions et frustrations.. qui sont la plupart du temps
                    à l'origine des échecs et qui ne constituent pas un mode
                    alimentaire viable dans le temps.
                </p>
                <p>
                    A la place, on adopte une alimentation saine et équilibrée
                    ainsi que PLAISIR que tu pourras garder à vie !
                    L'alimentation c'est aussi prendre soin de soi et sa santé,
                    en apportant l'énergie et les nutriments dont ton corps à
                    besoin !
                </p>
            </div>
        </div>
    )
}

export default Suivisphotoblock
