import React from 'react'
import '../styles/components/footer.scss'
import logoinsta from '../assets/img/logoinstamarron.svg'
import logotel from '../assets/img/logotelmarron.svg'
import Logocoach from './Logocoach'
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer">
            <div className="links-container third-bloc-footer">
                <div className="insta-link footer-link-block">
                    <a
                        href="https://www.instagram.com/sandr.l/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={logoinsta} alt="Instagram" />
                        <p>Suis moi sur Instagram !</p>
                    </a>
                </div>
                <div className="whatsapp-link footer-link-block">
                    <a href="tel:+33661426129">
                        <img src={logotel} alt="Whatsapp" />
                        <p>Envoies moi un message Whatsapp</p>
                    </a>
                </div>
            </div>
            <div className="logofooter third-bloc-footer">
                <Logocoach />
                <p>
                    Créé par Charles Huault, CH-WebVision © . Tous droits
                    reservés
                </p>
            </div>
            <div className="legal-texts third-bloc-footer">
                <NavLink to="/mentionslegales">
                    <p>Mentions Légales</p>
                </NavLink>
                <NavLink to="/politiqueconfidentialite">
                    <p>Politique de Confidentialité</p>
                </NavLink>
            </div>
        </div>
    )
}

export default Footer
