import React from 'react'
import imgsandra from '../assets/img/photoshoot-couleur-4.jpg'
import '../styles/components/accueilphotoblock.scss'

const Accueilphotoblock = () => {
    return (
        <div className="photoblock-container">
            <div className="photoblock-title">
                <h2>Je suis Sandra, ta Conseillère en Nutrition</h2>
            </div>
            <div className="photoblock-imgtext-container">
                <div className="photoblock-img">
                    <img src={imgsandra} alt="Sandra" />
                </div>
                <div className="photoblock-text">
                    <p>
                        Envie de reprendre ton alimentation en main ? <br />
                        Prendre de meilleures habitudes alimentaires ? <br />
                        Atteindre tes objectifs physiques sans t'affamer <br />
                        ou te restreindre ? <br />
                        Je te propose de revoir ensemble tes habitudes
                        alimentaires afin de déterminer une alimentation saine
                        qui te conviendra dans le temps.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Accueilphotoblock
