import React, { useRef, useEffect, useState } from 'react'
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import '../styles/components/carousel.scss'
import imgcarouselslide1 from '../assets/img/imgcarouselslide1nb.jpg'
import imgcarouselslide2 from '../assets/img/imgcarouselslide2.png'
import imgcarouselslide3 from '../assets/img/imgcarouselslide3nb.jpg'
import imgcarouselslide4 from '../assets/img/photoshoot-couleur-3.jpg'
import imgcarouselslide5 from '../assets/img/photoshoot-couleur-8.jpg'

const Carousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const sliderRef = useRef(null)
    const timerRef = useRef(null)

    const totalSlides = 5

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides)
        }, 10000)

        timerRef.current = interval

        return () => clearInterval(interval)
    }, [])

    const handleSlideChange = (newSlide) => {
        setCurrentSlide(newSlide)
    }

    const handleNextButtonClick = () => {
        if (currentSlide === totalSlides - 1) {
            // Si nous sommes sur la dernière diapositive, revenir à la première
            setCurrentSlide(0)
        } else {
            // Sinon, passer à la diapositive suivante normalement
            setCurrentSlide((prevSlide) => prevSlide + 1)

            // Réinitialiser le timer
            clearInterval(timerRef.current)
            timerRef.current = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides)
            }, 10000)
        }
    }

    return (
        <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={120}
            totalSlides={5}
            className={'carousel-block'}
            currentSlide={currentSlide}
            isIntrinsicHeight
        >
            <Slider ref={sliderRef} infinite>
                <Slide index={0} onChange={() => handleSlideChange(0)}>
                    <div className="carousel-img-text">
                        <img src={imgcarouselslide1} alt="Sandra 1" />
                        <p>
                            Je vous partage mon expérience et mon rapport avec
                            la nourriture, qui comme pour beaucoup, n'a pas
                            toujours été simple. Plus jeune, j'ai traversé
                            plusieurs périodes de surpoids. A l'adolescence, on
                            pouvait parler d'une alimentation catastrophique !
                            Mon alimentation se résumait en clair à la
                            consommation de produits ultra transformés et de
                            plats préparés.
                        </p>
                    </div>
                </Slide>
                <Slide index={1} onChange={() => handleSlideChange(1)}>
                    <div className="carousel-img-text">
                        <img src={imgcarouselslide2} alt="Sandra 2" />
                        <p>
                            En 2016, j'entame une première perte de poids, en
                            limitant la consommation de plats préparés, de
                            charcuterie et de matières grasses, avec une
                            fâcheuse tendance à diaboliser certains aliments et
                            en y ajoutant une activité sportive assez régulière.
                            Malheureusement, comme pour tout régime drastique,
                            j'ai repris le poids perdu quelques temps après.
                        </p>
                    </div>
                </Slide>
                <Slide index={2} onChange={() => handleSlideChange(2)}>
                    <div className="carousel-img-text">
                        <img src={imgcarouselslide3} alt="Sandra 3" />
                        <p>
                            En 2020, alors que je suis à nouveau en surpoids, je
                            profite du second confinement pour retrouver le goût
                            de cuisiner et d'exercer une activité physique à la
                            maison sous forme de renforcement musculaire et de
                            hit. Lors du retour à la « vie normale », les bonnes
                            habitudes que j'avais prises ont perduré.
                        </p>
                    </div>
                </Slide>
                <Slide index={3} onChange={() => handleSlideChange(3)}>
                    <div className="carousel-img-text">
                        <img src={imgcarouselslide4} alt="Sandra 4" />
                        <p>
                            En 2022, je retrouve une silhouette dans laquelle je
                            me sens bien et en confiance. Je décide donc de me
                            former par le biais d'un certificat de coach en
                            nutrition, afin de développer mes connaissances
                            personnelles sur la santé alimentaire d'une part, et
                            d'autres part pouvoir transmettre cette nouvelle
                            passion afin d'aider les autres à atteindre leur
                            objectif et transmettre des conseils nutritionnels.
                        </p>
                    </div>
                </Slide>
                <Slide index={4} onChange={() => handleSlideChange(4)}>
                    <div className="carousel-img-text">
                        <img src={imgcarouselslide5} alt="Sandra 5" />
                        <p>
                            Aujourd'hui, j'ai trouvé un bien-être alimentaire,
                            entre des plats gourmands et des repas plaisir
                            chaque jour, une activité physique qui me plaît, et
                            donc des objectifs durables et un sentiment de
                            bien-être.
                        </p>
                    </div>
                </Slide>
            </Slider>
            <ButtonBack className={'btn-arrow'}>
                <div className="arrow-left"></div>
            </ButtonBack>
            <div className={'btn-arrow'} onClick={handleNextButtonClick}>
                {/* Utilisation du gestionnaire d'événements personnalisé sur le conteneur de la flèche droite */}
                <div className="arrow-right"></div>
            </div>
        </CarouselProvider>
    )
}

export default Carousel
