import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Modal from 'react-modal'
import Error from './pages/Error'
import Suivis from './pages/Suivis'
import Prestations from './pages/Prestations'
import EBook from './pages/EBook'
import Apropos from './pages/Apropos'
import Contact from './pages/Contact'
import Mentions from './pages/Mentionslegales'
import Politiqueconf from './pages/Politiqueconf'

const App = () => {
    Modal.setAppElement('#root')
    return (
        <div className="Page">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/suivis" element={<Suivis />} />
                    <Route path="/prestations" element={<Prestations />} />
                    <Route path="/ebook" element={<EBook />} />
                    <Route path="/apropos" element={<Apropos />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/mentionslegales" element={<Mentions />} />
                    <Route
                        path="/politiqueconfidentialite"
                        element={<Politiqueconf />}
                    />
                    <Route path="*" element={<Error />} />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
