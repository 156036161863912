import React from 'react'
import '../styles/components/reservationsblock.scss'
import { InlineWidget } from 'react-calendly'

const Reservationsblock = () => {
    return (
        <div className="reservations-container">
            <h3>Réserves dès maintenant ton appel découverte gratuit !</h3>
            <div className="calendly-widget">
                <InlineWidget url="https://calendly.com/sandralagouarde0109/30min" />
            </div>
        </div>
    )
}

export default Reservationsblock
