import React from 'react'
import '../styles/components/accueiltexteblock.scss'
import target from '../assets/img/logociblemarron.svg'
import handshake from '../assets/img/logohandshakemarron.svg'
import document from '../assets/img/logodocumentmarron.svg'
import calendar from '../assets/img/calendarlogomarron.svg'
import thumb from '../assets/img/logothumbmarron.svg'

const Accueiltexteblock = () => {
    return (
        <div className="accueiltexteblock-container">
            <div className="target logo-text-block">
                <img src={target} alt="Cible" />
                <p>
                    Quel que soit ton objectif, prise ou perte de poids, prendre
                    des habitudes plus saines, ou simplement des conseils
                    nutritionnels sur la répartition des aliments. Je te propose
                    un échange globale sur l'aspect nutritif et un bilan
                    totalement personnalisé, basé sur ton histoire.
                </p>
            </div>
            <div className="handshake logo-text-block">
                <img src={handshake} alt="Mains serrées" />
                <p>
                    {' '}
                    Lors des consultations, nous échangerons ensemble sur le
                    meilleur suivi adapté à tes objectifs. Je pourrais évaluer
                    au mieux tes besoins, tes habitudes alimentaires, ton
                    hygiène de vie globale ainsi que tes contraintes de vie...
                    Afin de te proposer un plan nutritionnel au plus adapté.
                </p>
            </div>
            <div className="document logo-text-block">
                <img src={document} alt="Feuille et Stylo" />
                <p>
                    {' '}
                    A l'issue du bilan nutritionnel, je te partagerai certains
                    supports pour t'accompagner dans ta diete afin que tu
                    possèdes toutes les cartes en main pour réussir. Fiche de
                    recettes, document de batchcooking, comment choisir ses
                    aliments transformés...
                </p>
            </div>
            <div className="calendar logo-text-block">
                <img src={calendar} alt="Calendrier" />
                <p>
                    {' '}
                    Prends rendez-vous dès aujourd'hui pour ton appel découverte
                    gratuit ! Si notre premier échange t'as convaincu,
                    définissons ensemble un créaneau pour ta consultation
                </p>
            </div>
            <div className="thumb logo-text-block">
                <img src={thumb} alt="Pouce levé" />
                <p>
                    Découvre les différentes formules proposées ! Tu es plutôt
                    autonome mais tu ne sais pas par où commencer ? Tu es besoin
                    d'accompagnement et de soutien sur le long terme ?
                    Choisissons ensemble la formule qui te convient ! Après
                    chaque consultation en ligne, tu recevras par email
                    récapitulatif de notre échange , des objectifs définis
                    ensemble ainsi que les documents adéquates pour démarrer ta
                    diete.{' '}
                </p>
            </div>
        </div>
    )
}

export default Accueiltexteblock
